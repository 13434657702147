var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mb-3",staticStyle:{"background-color":"#fafafa"}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.handleNew()}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.EVENT"))+" ")]):_vm._e()],1),(_vm.eventCategoryCollection)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-switch',{attrs:{"disabled":_vm.loadingTable,"label":"Lejárt események is"},on:{"change":_vm.handleSwitchActiveEventsShow},model:{value:(_vm.activeEventsShow),callback:function ($$v) {_vm.activeEventsShow=$$v},expression:"activeEventsShow"}})],1):_vm._e(),_c('v-spacer'),_c('v-col',[_c('div',{staticClass:"d-flex flex-row ml-auto"},[_c('v-text-field',{staticClass:"pb-5",attrs:{"append-icon":"search","label":"Keresés az eseményekben","single-line":"","hide-details":""},on:{"change":_vm.handleSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-icon',{staticClass:"align-self-center mb-3 mx-3",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.searchTerm = null;
                _vm.options.searchTerm = null;}}},[_vm._v(" flaticon2-delete ")])],1)])],1),_c('v-row',{staticStyle:{"background-color":"#f6f5f4"}},[(_vm.eventCategoryCollection)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"8","md":"8"}},[_c('p',{staticClass:"py-1 my-0 header-category",class:{ active: !_vm.options.category_id },on:{"click":_vm.handleResetHeaderCategoryFilter}},[_vm._v(" MINDEN KATEGÓRIA ")])]):_vm._e()],1),_c('v-row',{staticClass:"mb-5",staticStyle:{"background-color":"#fafafa"}},[(_vm.eventCategoryCollection)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.eventCategoryCollection),function(category){return _c('v-col',{key:category.id,staticClass:"py-1 header-category",attrs:{"cols":"12","sm":"3","md":"2"}},[_c('span',{class:{ active: _vm.options.category_id == category.id },on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectHeaderCategory(category)}}},[_vm._v(_vm._s(category.name)+" ")])])}),1)],1):_vm._e()],1),_c('EventModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint,"eventCategoryCollection":_vm.eventCategoryCollection},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm}}),(_vm.eventCollection.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventCollection,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [20, 50, 100],
        }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.translated",fn:function(ref){
        var item = ref.item;
return [(item.translated)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.tableCategories",fn:function(ref){
        var item = ref.item;
return _vm._l((item.categories),function(category){return _c('span',{key:category.id},[_vm._v(" "+_vm._s(_vm.getEventCategoryByID(category).name)+" ")])})}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(_vm.statuses)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("STATUSES." + _vm.statuses.events.event[item.status].value))+" ")]):_vm._e()]}},{key:"item.date_from",fn:function(ref){
        var item = ref.item;
return [(item && item.date_from)?_c('span',[_vm._v(" "+_vm._s(new Date(item.date_from).toLocaleString("hu-HU", { timeZone: "UTC", }))+" ")]):_vm._e()]}},{key:"item.date_to",fn:function(ref){
        var item = ref.item;
return [(item && item.date_to)?_c('span',[_vm._v(" "+_vm._s(new Date(item.date_to).toLocaleString("hu-HU", { timeZone: "UTC", }))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,false,376857208)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }